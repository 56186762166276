import { toggleCurtains } from "../fragment/countdown-timer/CountdownTimerSlice";
import { decrement, increment } from "../fragment/counter/CounterSlice";
import { play, playFor, unload } from "../fragment/media-player/MediaPlayerSlice";
import { appear } from "../fragment/neon-title/NeonTitleSlice";

const MidiConfig =
{ 
    
    triggers:
    [
        { note: 11, function: decrement() },
        { note: 12, function: increment() },

        { note: 21, function: appear({ text: "Exclusive", amount: 8, color: "#f46036", duration: 5000, }) },
        { note: 22, function: appear({ text: "TimeWaster", amount: 8, color: "#52ffb8", duration: 5000, }) },

        { note: 15, function: playFor({ref: "beetje zuipen", duration: 9.5}) },
        { note: 16, function: playFor({ref: "moedige haas", duration: 4})},

        { note: 25, function: play("wat een goed idee")},
        { note: 26, function: play("je doet maar") },

        { note: 71, function: play("rct cash") },
        { note: 72, function: play("duolingo")},

        { note: 81, function: play("airhorn") },
        { note: 82, function: play("grolsch") },
        { note: 83, function: play("shotjee") },
        { note: 84, function: play("not enough jäger") },

        { note: 88, color: 4, function: toggleCurtains()},
        { note: 18, color: 5, function: unload() }, // Stop all media
    ]
}

export { MidiConfig }
